import "./SkillSection.css";
import DeviconCard from "./DeviconCard";
import useWindowDimensions from '../../useWindowDimensions';

import { deviconTuples } from "../../devicons";

/**
 *
 */

function SkillSection() {
    const { width } = useWindowDimensions();

    let cardWidth = '15vw';
    switch (true) {
        case width < 340:
            cardWidth = '46px';
            break;
        case width < 500:
            cardWidth = '60px';
            break;
        case width < 700:
            cardWidth = '80px';
            break;
        case width >= 700:
            cardWidth = '120px';
            break;
    }

    return (
        <section
            className="skillSection d-flex flex-column align-items-center justify-content-center"
            id="skills"
        >
            <h2 className="font-link skillHeading align-middle">Technical Skills</h2>

            <div className="deviconGridContainer align-middle d-flex flex-wrap justify-content-center">
                {
                    deviconTuples.map(
                        (t, idx) => {
                            return (
                                <DeviconCard
                                    key={idx}
                                    width={`${cardWidth}`}
                                    height={`auto`}
                                    source={t[0]}
                                    alt={t[1]}
                                    title={t[2]}
                                />
                            );
                        }
                    )
                }
            </div>
        </section>
    );
}

export default SkillSection;
