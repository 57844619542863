export const deviconTuples = [
  [
    "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/javascript/javascript-original.svg",
    "dev-icon-javascript",
    "JavaScript",
  ],
  [
    "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/typescript/typescript-original.svg",
    "dev-icon-typescript",
    "TypeScript",
  ],
  [
    "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/python/python-original.svg",
    "dev-icon-python",
    "Python",
  ],
  [
    "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/postgresql/postgresql-original.svg",
    "dev-icon-postgresql",
    "PostgreSQL",
  ],
  [
    "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/nextjs/nextjs-original.svg",
    "dev-icon-next-js",
    "Next JS",
  ],
  [
    "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/react/react-original.svg",
    "dev-icon-react",
    "React",
  ],
  [
    "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/django/django-plain.svg",
    "dev-icon-django",
    "Django",
  ],
  [
    "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/nodejs/nodejs-original.svg",
    "dev-icon-node",
    `NodeJS`,
  ],
  [
    "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/express/express-original.svg",
    "dev-icon-express",
    "Express",
  ],
  [
    "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/flask/flask-original.svg",
    "dev-icon-flask",
    "Flask",
  ],
  [
    "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/sqlalchemy/sqlalchemy-original.svg",
    "dev-icon-sql-alchemy",
    "SQLAlchemy",
  ],
  [
    "https://cdn.jsdelivr.net/gh/devicons/devicon@latest/icons/amazonwebservices/amazonwebservices-original-wordmark.svg",
    "dev-icon-aws",
    "AWS",
  ],
  [
    "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/docker/docker-plain.svg",
    "dev-icon-docker",
    "Docker",
  ],
  [
    "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/html5/html5-original.svg",
    "dev-icon-html",
    "HTML5",
  ],
  [
    "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/css3/css3-original.svg",
    "dev-icon-css",
    "CSS3",
  ],
  [
    "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/bootstrap/bootstrap-original.svg",
    "dev-icon-bootstrap",
    "Bootstrap",
  ],
  [
    "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/jquery/jquery-original.svg",
    "dev-icon-jquery",
    "jQuery",
  ],
  [
    "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/git/git-original.svg",
    "dev-icon-git",
    "Git",
  ],
  [
    "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/github/github-original.svg",
    "dev-icon-github",
    "GitHub",
  ],
  [
    "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/vscode/vscode-original.svg",
    "dev-icon-vscode",
    "VSCode ",
  ],
  [
    "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/npm/npm-original-wordmark.svg",
    "dev-icon-npm",
    "npm",
  ],
  [
    "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/webpack/webpack-original.svg",
    "dev-icon-webpack",
    "webpack",
  ],
  [
    "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/jest/jest-plain.svg",
    "dev-icon-jest",
    "Jest",
  ],
]
