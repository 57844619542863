import './App.css';
import IntroSection from './components/IntroSection';
import AboutSection from './components/AboutSection';
import SkillSection from './components/section-skills/SkillSection';
import ProjectSection from './components/section-projects/ProjectSection';
import WorkSampleSection from './components/section-projects/WorkSampleSection';
import NavBar from './components/nav-bar/NavBar';

function App() {
  return (
    <div id="App">

      <NavBar />

      <IntroSection />

      <AboutSection />

      <SkillSection />

      <WorkSampleSection />

      <ProjectSection />

    </div>
  );
}

export default App;
