import './NavBar.css';
import NavButtonScroll from './NavButtonScroll';
import NavButtonExt from './NavButtonExt';
import useWindowDimensions from '../../useWindowDimensions';

import resumePDF from "../../Christopher_Chen_resume.pdf";
import BurgerMenu from './BurgerMenu';

/**
 *
 */

function NavBar() {
    const SCROLL_TUPLES = [
        ["intro", "Home"],
        ["about-me", "About"],
        ["skills", "Skills"],
        ["work-samples", "Work Samples"],
        ["projects", "Projects"],
    ];

    const LINKS_EXT = [
        [
            resumePDF,
            `bi-file-pdf`,
            `Resume`,
        ],
        [
            "https://github.com/clchen-arcadia",
            `bi bi-github`,
            `GitHub`,
        ],
        [
            "https://www.linkedin.com/in/christopherlichen/",
            `bi bi-linkedin`,
            `LinkedIn`,
        ],
    ];

    const { width } = useWindowDimensions();

    const isBurgerMenu = width < 550;
    const hideText = width < 800;

    return (
        <div className="navBar d-flex justify-content-between">
            <div
                className='d-flex align-items-center'
            >
                {
                    isBurgerMenu
                    ?
                    <div>
                        <BurgerMenu
                            scrollTuples={SCROLL_TUPLES}
                        />
                    </div>
                    :
                    SCROLL_TUPLES.map(
                        (t, idx) => {
                            return (
                                <NavButtonScroll
                                    key={idx}
                                    idToScroll={t[0]}
                                    title={t[1]}
                                />
                            );
                        }
                    )
                }
            </div>

            <div className="d-flex align-items-center"
            >

                {
                    LINKS_EXT.map(
                        (t, idx) => {
                            return (
                                <NavButtonExt
                                    key={idx}
                                    linkExt={t[0]}
                                    iconClass={t[1]}
                                    title={t[2]}
                                    hideText={hideText}
                                />
                            );
                        }
                    )
                }

            </div>

        </div>
    );
}

export default NavBar;
