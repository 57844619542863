import { useEffect, useState } from "react";

import "./PhotoCarousel.css";
import PhotoCard from "./PhotoCard";
import useWindowDimensions from "../../useWindowDimensions";

/**
 *
 */

function PhotoCarousel({ photos, title }) {
    const [currCardIdx, setCurrCardIdx] = useState(0);
    const currCard = photos[currCardIdx];

    const [dimensions, setDimensions] = useState({
        width: 600,
        height: 450,
    });

    const { width: viewWidth } = useWindowDimensions();

    useEffect(
        function rerenderOnResize() {
            const newWidth = viewWidth < 600 ? viewWidth : 600;
            const newHeight = newWidth * (450 / 600);
            setDimensions({
                width: newWidth,
                height: newHeight,
            });
        },
        [viewWidth]
    );

    function goBack(): void {
        if (photos.length === 0) return;
        if (currCardIdx === 0) setCurrCardIdx(photos.length - 1);
        else setCurrCardIdx(currCardIdx - 1);
    }

    function goForward(): void {
        if (photos.length === 0) return;
        if (currCardIdx === photos.length - 1) setCurrCardIdx(0);
        else setCurrCardIdx(currCardIdx + 1);
    }

    return (
        <div className="PhotoCarousel">

            <div
                className="controlLayer d-flex justify-content-between align-items-center"
                style={{ width: dimensions.width, height: dimensions.height }}
            >

                <div onClick={goBack} >
                    <i
                        className={`CarouselIcon CarouselIconLeft d-inline bi bi-chevron-compact-left`}
                    />
                </div>

                <div onClick={goForward}>
                    <i
                        className={`CarouselIcon CarouselIconRight d-inline bi bi-chevron-compact-right`}
                    />
                </div>

            </div>

            <div className={"d-inline"}>
                <PhotoCard
                    src={currCard.src}
                    alt={currCard.alt}
                    width={dimensions.width}
                    height={dimensions.height}
                />
            </div>

        </div>
    );
}

export default PhotoCarousel;
