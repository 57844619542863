import "./ProjectSection.css";
import ProjectCard from "./ProjectCard";

import { warblerPhotos, joblyPhotos, sharebnbPhotos, supportlyPhotos } from '../../photos';

/**
 *
 */

function ProjectSection() {
    const PROJECT_OBJECTS = [
        {
            title: `Supportly`,
            blurb: `A simple ticket management app where anonymous users can submit tickets and admins can manage and respond to them. Built with React, Flask, and PostgreSQL.`,
            skills: [
                'Flask Python',
                'DBMS, PostgreSQL',
                'ORM, SQLAlchemy',
                'HTML Templating',
                'AuthN/Z',
            ],
            linkTuples: [
                [`Live Demo`, "https://supportly.netlify.app/"],
                [`GitHub Repo`, "https://github.com/clchen-arcadia/supportly"],
            ],
            photos: supportlyPhotos,
        },
        {
            title: `Warbler`,
            blurb: `A Twitter clone where users can post, like, and follow other users. Built with Flask Python, ORM SQL Alchemy, and DBMS PostgreSQL.`,
            skills: [
                'Flask Python',
                'DBMS, PostgreSQL',
                'ORM, SQLAlchemy',
                'HTML Templating',
                'AuthN/Z',
            ],
            linkTuples: [
                [`Live Demo`, "https://flask-warbler-bt2x.onrender.com/"],
                [`GitHub Repo`, "https://github.com/clchen-arcadia/flask-warbler"],
            ],
            photos: warblerPhotos
        },
        {
            title: `Jobly`,
            blurb: `A job board site built with a frontend in React.js, a RESTful API in Express.js, and a database with PostgreSQL.`,
            skills: [
                'React JS',
                'Express Node JS',
                'REST APIs',
                'AuthN/Z',
            ],
            linkTuples: [
                [`Live Demo`, "https://react-jobly-2e3531d9a225.netlify.app/"],
                [`GitHub Repo Frontend`, "https://github.com/clchen-arcadia/react-jobly"],
                [`GitHub Repo Backend`, "https://github.com/clchen-arcadia/express-jobly"],
            ],
            photos: joblyPhotos
        },
        {
            title: `ShareBnB`,
            blurb: `A AirBnB clone built with React and Flask. User photo upload and download handled with AWS S3.`,
            skills: [
                'React JS',
                'Flask Python',
                'REST APIs',
                'AuthN/Z',
                'AWS S3',
            ],
            linkTuples: [
                [`Live Demo`, "https://share-bnb.netlify.app/"],
                [`GitHub Repo`, "https://github.com/clchen-arcadia/share-bnb"],
            ],
            photos: sharebnbPhotos
        },
    ];

    return (
        <section
            className="projectSection d-flex flex-column align-items-center justify-content-center"
            id="projects"
        >
            <h2
                className="font-link projectHeading align-middle"
            >
                Projects
            </h2>

            {
                PROJECT_OBJECTS.map(
                    (obj, idx) => {
                        return (
                            <div key={idx} className={'ProjectCardWrapper'}>
                                <ProjectCard
                                    key={idx}
                                    title={obj.title}
                                    blurb={obj.blurb}
                                    linkTuples={obj.linkTuples}
                                    photos={obj.photos}
                                />
                            </div>
                        );
                    }
                )
            }

        </section>
    );
}

export default ProjectSection;
