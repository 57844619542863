import { useState } from 'react';
import './BurgerMenu.css';
import NavButtonScroll from './NavButtonScroll';

/**
 *
 */

function BurgerMenu({ scrollTuples }) {
    const [isHamburgerOpen, setIsHamburgerOpen] = useState(false);

    function toggleHamburger() {
        setIsHamburgerOpen(!isHamburgerOpen);
    }

    function closeHamburger() {
        setIsHamburgerOpen(false);
    }

    return (
        <div className='burgerMenu'>

            <i
                className='burgerIcon bi bi-list'
                onClick={toggleHamburger}
            />

            {
                isHamburgerOpen
                &&
                <div className='burgerMenuButtonsArea'
                >
                    {scrollTuples.map(
                        (t, idx) => {
                            return (
                                <div
                                    onClick={closeHamburger}
                                    className='burgerMenuButton'
                                    key={idx}
                                >
                                    <NavButtonScroll
                                        key={idx}
                                        idToScroll={t[0]}
                                        title={t[1]}
                                    />
                                </div>
                            );
                        }
                    )
                    }
                </div>
            }
        </div>
    );
}

export default BurgerMenu;
