import './ProjectCard.css';
import PhotoCarousel from './PhotoCarousel';
import useWindowDimensions from '../../useWindowDimensions';

/**
 *
 */

function ProjectCard({ title, blurb, linkTuples, photos }) {
    const { width } = useWindowDimensions();

    const isTooNarrow = width < 1000;
    const flexClass = isTooNarrow ? 'flex-column' : 'flex-row';

    return (
        <div className={`projectCard d-flex ${flexClass}`}>

            <div className="projectInfo d-inline">

                <div
                    className='projectInfoWrapper'
                >
                    <h4>{title}</h4>
                    <p>
                        {blurb}
                    </p>
                    <p>
                        {
                            linkTuples.map(
                                (t, idx) => {
                                    return (
                                        <a
                                            key={idx}
                                            href={t[1]}
                                            target="_blank"
                                            rel="noreferrer noopener"
                                        >
                                            {t[0]}
                                        </a>
                                    );
                                }
                            ).reduce((acc, x) => acc === null ? [x] : [acc, ' | ', x], null)
                        }
                    </p>
                </div>

            </div>

            <div className='d-inline'>
                <PhotoCarousel
                    photos={photos}
                    title={title}
                />
            </div>

        </div>
    );

}

export default ProjectCard;
