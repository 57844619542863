import { useState } from "react";
import "./PhotoCard.css";

interface IDimensions {
    width: string | number;
    height: string | number;
}

/**
 *
 */

function PhotoCard({ src, alt, width, height }) {
    const [imageStyles, setImageStyles] = useState<IDimensions>({ width: 'auto', height: 'auto' });

    function onImgLoad(img) {
        const imgWidth = img.target.width;
        const imgHeight = img.target.height;
        const isTooWide = imgWidth / imgHeight >= 600 / 450;

        if (isTooWide) {
            setImageStyles({
                width: width,
                height: 'auto',
            });
        } else {
            setImageStyles({
                height: height,
                width: 'auto',
            });
        }

    }

    return (
        <div
        className={'Card shadow border d-flex justify-content-center align-items-center'}
        style={{
            width, height
        }}
        >
            <img
                className={'Card-image'}
                onLoad={onImgLoad.bind(this)}
                src={src}
                alt={alt}
                style={imageStyles}
            />
        </div>
    );
}

export default PhotoCard;
