import './NavButtonScroll.css';

function NavButtonScroll({ idToScroll, title }) {
    return (
        <div className="navButtonScroll"
            onClick={
                () => window.location.replace(`/#${idToScroll}`)
            }
        >
            {title}
        </div>
    );
}

export default NavButtonScroll;
