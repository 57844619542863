import './NavButtonExt.css';

function NavButtonExt({ linkExt, iconClass, title, hideText }) {
    const myStyles = hideText ? { display: 'none' } : {}

    return (
        <div
            className='buttonExt d-flex align-items-center'
        >

            <a
                href={linkExt}
                target="_blank"
                rel="noreferrer noopener"
                className='buttonExtA d-flex align-items-center'
            >
                <i
                    className={`${iconClass} m-2`}
                />
                <span style={myStyles}>
                    {title}
                </span>
            </a>

        </div>
    );
}

export default NavButtonExt;
