export const projectTitleOne = `Asdf`

export const projectBlurbOne = `asdf`;

export const aboutMeBlurb = `
Hi there! I’m Chris and I’m a full stack software engineer. I work with web development frameworks in JavaScript and Python.

I graduated from Rithm School’s web development coding bootcamp and have been seeking new opportunities, networking, and working on new things! Before that I had studied biochemistry and worked in laboratory research.

Fun facts:
📺 Favorite show: BoJack Horseman
📔 Favorite book: A Tree Grows In Brooklyn
📕 Favorite book I read recently: I’m Glad My Mom Died
🎾 Hobbies: I love hiking, board games, and cooking!

Reach out if you want to ask me anything about my projects, career change, or anything else!
`
