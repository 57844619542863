import './IntroSection.css';

/**
 * Renders the IntroSection component.
 *
 * App --> IntroSection
 *
 */

function IntroSection() {
    return (
        <section
            className="introSection d-flex flex-column align-items-center justify-content-center"
            id="intro"
        >
            <h1
                className="font-link introHeading1"
            >
                Hello World,<br />I'm Christopher Chen
            </h1>

            <h2
                className="font-link introHeading2"
            >
                Full&nbsp;Stack Software&nbsp;Engineer
            </h2>
        </section>
    );
}

export default IntroSection;
